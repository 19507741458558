import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css'; // Ensure this CSS loads early enough to avoid any flash

// Lazy load all components
const Home = React.lazy(() => import('./components/Home'));
const Features = React.lazy(() => import('./components/Features'));
const About = React.lazy(() => import('./components/About'));
const Blog = React.lazy(() => import('./components/Blog'));
const RequestDemo = React.lazy(() => import('./components/RequestDemo'));
const NewsAndSentiment = React.lazy(() => import('./features/NewsAndSentiment/NewsAndSentiment'));
const DataAnalytics = React.lazy(() => import('./features/DataAnalytics/DataAnalytics'));
const TextAndVideoInsights = React.lazy(() => import('./features/TextAndVideoInsights/TextAndVideoInsights'));
const AIBasedSignals = React.lazy(() => import('./features/AIBasedSignals/AIBasedSignals'));
const AutonomousInvestmentAgents = React.lazy(() => import('./features/AutonomousInvestmentAgents/AutonomousInvestmentAgents'));
const ExtensiveDashboards = React.lazy(() => import('./features/ExtensiveDashboards/ExtensiveDashboards'));
const ReportStudio = React.lazy(() => import('./features/ReportStudio/ReportStudio'));
const SearchEngine = React.lazy(() => import('./features/SearchEngine/SearchEngine'));

const Header = ({ currentSection }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setIsMobileMenuOpen(false);
    }
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header>
      <div className="header-content">
        <div className="logo-container" onClick={() => window.location.href = '/'}>
          <span className="logo-text" style={{ cursor: 'pointer' }}>
            Fina.ai
          </span>
        </div>

        <nav>
          <ul className="desktop-nav">
            <li
              onClick={() => scrollToSection('home')}
              className={currentSection === 'home' ? 'active' : ''}
            >
              Home
            </li>
            <li
              onClick={() => scrollToSection('features')}
              className={currentSection === 'features' ? 'active' : ''}
            >
              Products
            </li>
            <li
              onClick={() => scrollToSection('about')}
              className={currentSection === 'about' ? 'active' : ''}
            >
              Solutions
            </li>
            <li>
              <a href="#request-demo" className="request-demo-button">
                Request Demo
              </a>
            </li>
          </ul>

          <div className="mobile-nav" onClick={toggleMobileMenu}>
            ☰
          </div>

          {isMobileMenuOpen && (
            <ul className="mobile-menu">
              <li onClick={() => scrollToSection('home')}>Home</li>
              <li onClick={() => scrollToSection('features')}>Products</li>
              <li onClick={() => scrollToSection('about')}>Solutions</li>
              <li onClick={() => scrollToSection('request-demo')}>Request Demo</li>
            </ul>
          )}
        </nav>
      </div>
    </header>
  );
};

const Footer = () => (
  <footer>
    <div className="footer-content">
      <div className="logo-container">
        <span className="logo-text">Fina.ai</span>
      </div>
      <p>&copy; 2024 Fina.ai. All rights reserved.</p>
    </div>
  </footer>
);

const MainContent = () => (
  <>
    <section id="home">
      <Home />
    </section>
    <section id="features">
      <Features />
    </section>
    <section id="about">
      <About />
    </section>
    <section id="request-demo">
      <RequestDemo />
    </section>
  </>
);

const FeaturesLinks = () => (
  <div id="features">
    <h2>Features</h2>
    <ul>
      <li>
        <Link to="/features/news-and-sentiment">News and Sentiment</Link>
      </li>
      <li>
        <Link to="/features/data-analytics">Data Analytics</Link>
      </li>
      <li>
        <Link to="/features/text-and-video-insights">Text and Video Insights</Link>
      </li>
      <li>
        <Link to="/features/ai-based-signals">AI-Based Signals</Link>
      </li>
      <li>
        <Link to="/features/investment-agents">Investment Agents</Link>
      </li>
      <li>
        <Link to="/features/extensive-dashboards">Extensive Dashboards</Link>
      </li>
      <li>
        <Link to="/features/report-studio">Instant Report Studio</Link>
      </li>
      <li>
        <Link to="/features/smart-search">Smart Search Engine</Link>
      </li>
    </ul>
  </div>
);

const App = () => {
  const [currentSection, setCurrentSection] = useState('');

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    const scrollPosition = window.scrollY + window.innerHeight / 3;

    let foundSection = '';
    sections.forEach((section) => {
      const offsetTop = section.offsetTop;
      const offsetHeight = section.offsetHeight;

      if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
        foundSection = section.getAttribute('id');
      }
    });

    setCurrentSection(foundSection);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Header currentSection={currentSection} />
        <Suspense fallback={<div className="loader-container"><div className="loader"></div></div>}>
          <Routes>
            <Route path="/" element={<MainContent />} />
            <Route path="/features" element={<FeaturesLinks />} />
            <Route path="/features/news-and-sentiment" element={<NewsAndSentiment />} />
            <Route path="/features/data-analytics" element={<DataAnalytics />} />
            <Route path="/features/text-and-video-insights" element={<TextAndVideoInsights />} />
            <Route path="/features/ai-based-signals" element={<AIBasedSignals />} />
            <Route path="/features/investment-agents" element={<AutonomousInvestmentAgents />} />
            <Route path="/features/extensive-dashboards" element={<ExtensiveDashboards />} />
            <Route path="/features/report-studio" element={<ReportStudio />} />
            <Route path="/features/smart-search" element={<SearchEngine />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/request-demo" element={<RequestDemo />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
